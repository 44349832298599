<template>
    <div>
        <fromSearch :object_='seriesList_' @searchFun='getList' />
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange" >
            <template #menu-left>
                <el-button type="primary"   @click="openView(0)">{{$t('button.add')}} </el-button>
            </template>
            <template #itemUrl='scope'>
                <img :src='scope.row.collectionImg' alt='22' class="img_"/>
            </template>
            <template #caozuo="scope">
                <el-button text type="primary" v-if='scope.row.createFrom !== 2'
                    size="small"    @click="openView(2,scope.row)">{{$t('button.add')}} </el-button>
                <el-button text type="primary"
                    size="small"    @click="openView(5,scope.row)">{{$t('button.look')}}  </el-button>
                <el-button text type="primary"
                    size="small"    @click="openView(1,scope.row)">{{$t('otherWallatNft.text1')}} </el-button>
            </template> 
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
        </avue-crud>   

        <el-dialog v-model="innerVisible1" :title="textArray[pageStyle]" >
            <addView v-if='pageStyle === 0' @successFun='successFun' ref='addView_'/>
            <writeView v-if='pageStyle === 1' @successFun='successFun' ref='writeView_'/>
            <addWork v-if='pageStyle === 2' @successFun='successFun' ref='addWork_'/>
            <workView v-if='pageStyle === 5' ref='workView_' />
            <template #footer>
                <el-button    @click="innerVisible1 = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"   @click="completeFun">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,nextTick,getCurrentInstance } from 'vue'
    import { seriesList } from '@/const/from/nft/seriesList'
    import fromSearch from '@/components/fromSearch.vue'
    import { tableOption1 } from '@/const/crud/nft/seriesList'
    import addView from './add'
    import writeView from './write'
    import workView from './workView'
    import addWork from './addWork'
    import { turnTimeFun } from '@/utils/util.js'   
    const { $t } = getCurrentInstance().proxy;

    import mixins from '@/views/mixins/page'
    let pageObj = mixins(['getNftcollectioninfoList'])
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj

    const tableOption = ref({}); tableOption.value = tableOption1
    const seriesList_ = ref(seriesList);
    const textArray = [$t('nfts.text16'),$t('otherWallatNft.text2'),'新增作品']

    const pageStyle = ref(0)
    const innerVisible1 = ref(false)
    const writeView_ = ref(null)
    const addView_ = ref(null)
    const workView_ = ref(null)
    const addWork_ = ref(null)
    
    const completeFun = ()=>{
        if(pageStyle.value === 1){
            writeView_.value.completeFun()
        }else if(pageStyle.value === 2){
            addWork_.value.completeFun()
        }else{
            addView_.value.completeFun()
        }
    }
    const openView = (e,parm)=>{ 
        innerVisible1.value = true
        pageStyle.value = e
        if(e === 1){
            nextTick(()=>{
                writeView_.value.setData1(parm)
            })
        }else if(e === 2){
            nextTick(()=>{
                addWork_.value.init_(parm)
            })
        }else if(e === 0){
            nextTick(()=>{
                addView_.value.setData1()
            })
        }else if(e === 5){
            nextTick(()=>{
                workView_.value.init_({
                    tenantId: parm.tenantId,
                    appId: parm.appId,
                    chain: parm.chain,
                    protocol: parm.protocol,
                    collectionName: parm.collectionName,
                })
            })
        }
    }
    const successFun = ()=>{
        innerVisible1.value = false
        getList(1)
    }
    getList(1)
</script>
<style lang="scss" scoped>
    .img_{
        width: 80px;height: 80px;
    }
</style>