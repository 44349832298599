import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const tableOption1 = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    height: 'auto',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 100,
        label: t('currencySetNft.name1'),
        prop: 'tenantName',
    },{
        width: 100,
        label: t('currencySetNft.name2'),
        prop: 'appName',
    }, {
        width: 100,
        label: t('nfts.name22'),
        prop: 'itemUrl',
    }, {
        width: 100,
        label: t('currencySetNft.text6'),
        prop: 'chain',
    }, {
        width:100,
        label: t('currencySetNft.text7'),
        prop: 'protocol'
    }, {
        width:130,
        label: t('nfts.name14'),
        prop: 'contractClassName'
    }, {
        width:200,
        label: t('nfts.name1'),
        prop: 'contractAddress'
    },{
        width:140,
        label: t('nfts.text13'),
        prop: 'collectionName'
    },{
        label: t('nfts.name2'),
        prop: 'description',
        width: 170,
        overHidden: true,
    },{
        width:100,
        label: '存储方式',
        prop: 'storageType',
        dicData: [{ label: 'IPFS', value: 1 },{label: '中心化',value:2}]
    },{
        width:100,
        label: 'Mint 权限',
        prop: 'mintType',
        props: {
            label: "label",
            value: "value"
        },
        dicData: [{ label: 'owner', value: 1 },{ label: '任何人', value: 2}],
    },{
        width: 240,
        label: 'Creator',
        prop: 'creator'
    },
    {
        width: 170,
        label: t('nfts.name18'),
        prop: 'createTime',
    },{
        width: 140,
        label: t("button.operation"),
        prop: 'caozuo',
        placeholder: t("inputTit.shuru"),
        fixed:'right',
    }]
}
export const coin1 = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 100,
        label: t('currencySetNft.text6'),
        prop: 'chain',
    },{
        width: 100,
        label: t('currencySetNft.text7'),
        prop: 'protocol',
    }, {
        width: 100,
        label: t('nfts.name14'),
        prop: 'contractName',
    }, {
        width: 100,
        label: t('nfts.name14'),
        prop: 'contractName',
    },  {
        width: 130,
        label: 'Mint 权限',
        prop: 'mintType',
        props: {
            label: "label",
            value: "value"
        },
        dicData: [{ label: 'owner', value: 1 },{ label: '任何人', value: 2}],
    },{
        width: 130,
        label: '适用场景',
        prop: 'supplyType',
        props: {
            label: "label",
            value: "value"
        },
        dicData: [{ label: '有最大量', value: 1 },{ label: '不限量', value: 2}],
    },
    {
        label: t('currencySetNft.name6'),
        prop: 'createTime',
        type: 'datetime',
        format: 'YYYY-MM-DD HH:mm:ss',
        valueFormat: 'YYYY-MM-DD HH:mm:ss',
        addDisplay: false,
        editDisplay: false,
    },{
        width:100,
        label: t('currencySetNft.text8'),
        prop: 'selection',
    },]
}