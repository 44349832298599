<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList' />
        <el-table :data="page.list" border v-loading='listLoading' height="350">
            <el-table-column prop="collectionName" :label="$t('nfts.name22')" width="100" >
                <template #default="scope">
                    <img :src='scope.row.itemUrl' alt='22' class="img_"/>
                </template>
            </el-table-column>
            <el-table-column prop="collectionName" :label="$t('nfts.text13')" width="150" />
            <el-table-column prop="itemName" :label="$t('withdrawNft.name11')" width="150" />
            <el-table-column prop="totalNum" :label="$t('nfts.name19')" width="80" />
            <el-table-column prop="tokenId" label="Token ID" width="100" />
            <el-table-column prop="nftOwnerAddr" label="Owner" width="250" />
            <el-table-column prop="storageWalletAddr" :label="$t('nfts.name5')" width="250" />
            <el-table-column prop="description" :label="$t('nfts.name2')" width="250" />
        </el-table>
        <pageCom :page='page' @sizeChange='sizeChange' @currentChange='currentChange'/>
    </div>
</template>
<script setup>
    import { ref,defineExpose } from 'vue'
    import mixins from '@/views/mixins/page'
    import fromSearch from '@/components/fromSearch.vue'
    import { workView } from '@/const/from/nft/work'
    import pageCom from '@/components/pageCom.vue'
    
    const object_ = ref({});object_.value = workView
    let pageObj = mixins(['getPageByGroup'])
    const {page,listLoading,sizeChange,currentChange,getList,updateOtherParm} = pageObj

    const init_ = (obj)=>{
        updateOtherParm(obj)
        getList()
    }
    defineExpose({init_})
</script>

<style lang="scss" scoped>
    .img_{
        width: 80px;height: 80px;
    }
</style>